import React,{Suspense, useState, useEffect} from 'react'
import Loading from './components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import adImg from './assets/images/pop/ad6.webp';

const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const FAQ = React.lazy(() => import('./pages/Faq'));
const HowItWorks = React.lazy(() => import('./pages/HowItWorks'));
const SearchQuery = React.lazy(() => import('./pages/SearchQuery'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('./pages/TermsCondition'));
const TestDetailPage = React.lazy(() => import('./pages/TestDetailPage'));
const AllCategories = React.lazy(() => import('./pages/AllCategories'));
const Category = React.lazy(() => import('./pages/Category'));
const Checkout = React.lazy(() => import('./pages/Checkout'));
const Blogs = React.lazy(() => import('./pages/AllBlogs'));
const BlogDetail = React.lazy(() => import('./pages/Blog'));
const ThankYou = React.lazy(() => import('./pages/Thankyou'));
const Square = React.lazy(() => import('./pages/Square'));
const Error404 = React.lazy(() => import('./pages/Error404'));
const Location = React.lazy(() => import('./pages/Location'));
const App = () => {

    const testCart = localStorage.getItem('testCart');
    const [showCanvas, setShowCanvas] = useState(false);
    const [isOpen, setIsOpen] = useState('');

    const urlParams = new URLSearchParams(window.location.search);
    
    const gclid = urlParams.get('gclid');
    if(gclid){
        sessionStorage.setItem('gclid', gclid);
    }
    

    const [cartState, setCartState] = useState(testCart ? JSON.parse(testCart) : []);
    if(!testCart){
        localStorage.setItem('testCart', JSON.stringify([]));
    }
    const addItemToCart = (item) => {
        const cart = JSON.parse(localStorage.getItem('testCart'));
        const itemExists = cart.find((cartItem) => cartItem.id === item.id);
        if(itemExists){
            const updatedCart = cart.map((cartItem) => {
                if(cartItem.id === item.id){
                    // return {
                        // ...cartItem,
                        // quantity: cartItem.quantity + 1
                        alert("Item already added to cart")
                    // }
                }
                return cartItem;

            })
            localStorage.setItem('testCart', JSON.stringify(updatedCart));
        }else{
            localStorage.setItem('testCart', JSON.stringify([...cart, {...item, quantity: 1}]));
            setCartState([...cart, {...item, quantity: 1}]);
        }
        // setCartState([...cart, {...item, quantity: 1}]);
        cartTotal();
        setShowCanvas(true);

    }

    const removeItemFromCart = (item) => {
        const cart = JSON.parse(localStorage.getItem('testCart'));
        const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);
        localStorage.setItem('testCart', JSON.stringify(updatedCart));
        setCartState(updatedCart);
        cartTotal();
        // setShowCanvas(true);
    }

    const updateItemQuantity = (item, quantity) => {
        if(quantity < 1){
            return;
        }
        const cart = JSON.parse(localStorage.getItem('testCart'));
        const updatedCart = cart.map((cartItem) => {
            if(cartItem.id === item.id){
                return {
                    ...cartItem,
                    quantity: quantity
                }
            }
            return cartItem;
        })
        localStorage.setItem('testCart', JSON.stringify(updatedCart));
        setCartState(updatedCart);
        cartTotal();

    }

    const cartTotal = () => {
        const cart = JSON.parse(localStorage.getItem('testCart'));
        // const total = cart.reduce((acc, item) => {
        //     return acc + (item.price * item.quantity);
        // }, 0)
        // return total;
        // loop through cart and get total
        let total = 0;
        for(let i = 0; i < cart.length; i++){
            total += cart[i].price * cart[i].quantity;
        }
        return total;
    }

    const clearCart = () => {
        localStorage.setItem('testCart', JSON.stringify([]));
        setCartState([]);
        cartTotal();

    }

    const getCart = () => {
        const items = JSON.parse(localStorage.getItem('testCart'));
        if(items.length === 0){
            setCartState([]);
        }else{
            setCartState(items);
        }
        
    }

    const checkItemInCart = (item) => {
        // if items in cart
        // console.log(item);
        const cart = JSON.parse(localStorage.getItem('testCart'));
        // check if item exists
        const itemExists = cart.find((cartItem) => cartItem.id === item);
        if(itemExists){
            return true;
        }else{
            return false;
        }
    }

    const DetailCartClose = () => {
        setShowCanvas(false)
     };

    const DetailCartShow = () => {
        setShowCanvas(true)
    };

    // list for functions
    const cartfunctions = {
        addItemToCart,
        removeItemFromCart,
        updateItemQuantity,
        clearCart,
        getCart,
        cartTotal,
        checkItemInCart
    }

    useEffect(() => {
        if (sessionStorage.getItem('popOpen') === 'true') {
            setIsOpen(false);
        }else{
            setTimeout(() => {
                setIsOpen(false);
            }, 5000); 
        }
    }, []);

    const togglePopup = () => {
        setIsOpen(!isOpen);
        sessionStorage.setItem('popOpen', true);
    }


    return (
        <>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/" exact element={<Home cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/about-us" element={<About cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/contact-us" element={<Contact cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/faqs" element={<FAQ cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/how-it-works" element={<HowItWorks cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/privacy-policy" element={<PrivacyPolicy cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/exam-categories" element={<AllCategories cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/exam-categories/:category" element={<Category cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/test/:test" element={<TestDetailPage cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/checkout" element={<Checkout cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/blogs" element={<Blogs cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/blogs/:blog" element={<BlogDetail cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path='/search/:query' element={<SearchQuery cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/thank-you" element={<ThankYou cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    <Route path="/square" element={<Square cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />
                    
                    <Route path="/location" element={<Location cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose}  /> } />

                    <Route path="*" element={<Error404 cartState={cartState} cartOperation={cartfunctions} setShowCanvas={setShowCanvas} showCanvas={showCanvas} DetailCartClose={DetailCartClose} isOpen={isOpen} adImg={adImg} togglePopup={togglePopup} /> } />


                </Routes>
                <ToastContainer 
                        position="bottom-left"
                        autoClose={5000}
                        />
            </Suspense>
        </>
    )
}

export default App